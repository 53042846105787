import React, { useContext, useState, useEffect } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Nav2 from "./layouts/nav/index2";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
import Main from "./layouts/Main";

/// Dashboard
import Home from "./components/Dashboard/Home";

/////Demo
//import Theme1 from "./components/Dashboard/Demo/Theme1";
//import Theme2 from "./components/Dashboard/Demo/Theme2";
//import Theme3 from "./components/Dashboard/Demo/Theme3";
//import Theme4 from "./components/Dashboard/Demo/Theme4";
//import Theme5 from "./components/Dashboard/Demo/Theme5";
//import Theme6 from "./components/Dashboard/Demo/Theme6";

/// File Manager


/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import EditProfile from "./components/AppsMenu/AppProfile/EditProfile";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";

/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import Customers from "./components/AppsMenu/Shop/Customers/Customers";

/// Charts


/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
import MainNouiSlider from "./components/PluginsMenu/NouiSlider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

/// paginas 
import Widget from "./pages/Widget";
import Marcacion from "./pages/Marcacion";
import Usuarios from "./pages/Usuarios";
import Liquidaciones from "./pages/Liquidaciones";
import Clientes from "./pages/Clientes";
import FichaUser from "./pages/Fichausuario";
import FichaCliente from "./pages/Fichaclientes.js";
import Sesiones from "./pages/Sesiones.js";
import Cierre from "./pages/liquidacion/Cierre.js"

/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import BootstrapTable from "./components/table/BootstrapTable";

/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import CKEditor from "./components/Forms/Summernote/SummerNote";

import FormValidation from "./components/Forms/FormValidation/FormValidation";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import Termino from "./pages/Terminos.js";
import FichaLiquidacion from "./pages/liquidacion/Fichaliquidacion.js";
import Opciones from "./pages/Opciones.js";
const Markup = () => {
  const routes = [
    

    //File Manager
  

    /// Apps
    { url: "app-profile", component: AppProfile },
    { url: "app-calender", component: Calendar },
    { url: "post-details", component: PostDetails },

    /// Shop
    { url: "ecom-product-grid", component: ProductGrid },
    { url: "ecom-product-list", component: ProductList },

    { url: "ecom-product-order", component: ProductOrder },
    { url: "ecom-checkout", component: Checkout },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-customers", component: Customers },

    /// Chart
   

    { url: "ui-typography", component: UiTypography },
    { url: "ui-grid", component: UiGrid },

    /// Plugin
    { url: "uc-select2", component: Select2 },

    { url: "uc-noui-slider", component: MainNouiSlider },
    { url: "map-jqvmap", component: JqvMap },

    /// Widget
    { url: "widget-basic", component: Widget },

    { url: "marcacion", component: Marcacion },
    { url: "usuarios", component: Usuarios },
    { url: "liquidaciones", component: Liquidaciones },
    { url: "clientes", component: Clientes },
    { url: "Fichausuario", component: FichaUser },
    { url: "Fichaclientes", component: FichaCliente },
    { url: "sesiones", component: Sesiones },
    { url: "Cierre", component: Cierre },
    { url: "FichaLiquidacion", component: FichaLiquidacion },
    { url: "Opciones", component: Opciones },
    /// Form
    { url: "form-element", component: Element },
    { url: "form-validation", component: FormValidation },

    /// table
    { url: "datatable-filtering", component: FilteringTable },
    { url: "datatable-sorting", component: SortingTable },
    { url: "table-bootstrap-basic", component: BootstrapTable },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");

  return (
    <>
      <Switch>
        {/* without layouts (nav, header, sidebar and footer) */}
        <Route path="/page-register" component={Registration} />
        <Route path="/page-lock-screen" component={LockScreen} />
        <Route path="/page-login" component={Login} />
        <Route path="/page-forgot-password" component={ForgotPassword} />
        <Route path="/page-error-400" component={Error400} />
        <Route path="/page-error-403" component={Error403} />
        <Route path="/page-error-404" component={Error404} />
        <Route path="/page-error-500" component={Error500} />
        <Route path="/page-error-503" component={Error503} />
        <Route path="/Termino" component={Termino} />

        <Route
          path={[
            "/",
            "/dashboard",
            "/dashboard-dark",
            "/header-theme",
            "/sidebar-compact",
            "/horizontal-sidebar",
            "/horizontal-theme",
            "/sidebar-mini",
          ]}
          exact
        >
          <div id="main-wrapper" className="wallet-bar-close active show">
            <Main>
              <Nav2 />
              <div
                className="content-body"
                style={{ minHeight: window.screen.height + 20 }}
              >
                <div className="container-fluid">
                  <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/dashboard" exact component={Home} />
                  </Switch>
                  <Footer />
                </div>
              </div>
            </Main>
          </div>
        </Route>
        <Route path={["/container-boxed"]} exact>
          <div id="main-wrapper" className=" show">
            <Main>
              <Nav />
              <div
                className="content-body"
                style={{ minHeight: window.screen.height + 20 }}
              >
                <div className="container-fluid">
                  <Switch>
                    {/* <Route path='/container-boxed' exact component={Theme2} />                                                      */}
                  </Switch>
                </div>
              </div>
              <Footer changeFooter="out-footer" />
            </Main>
          </div>
        </Route>

        {/* footer change */}
        <Route
          path={[
            "/form-ckeditor",
            "/form-pickers",
            "/form-wizard",
            "/uc-sweetalert",
            "/map-jqvmap",
            "/uc-lightgallery",
            "/edit-profile",
            "/ecom-product-detail",
            "/uc-toastr",
            "/ecom-invoice",
            "/chart-chartist",
          ]}
          exact
        >
          <div id="main-wrapper" className="show">
            <Main>
              <Nav />
              <div
                className="content-body"
                style={{ minHeight: window.screen.height + 20 }}
              >
                <div className="container-fluid">
                  <Switch>
                    <Route path="/form-ckeditor" exact component={CKEditor} />
                  
                    <Route path="/form-wizard" exact component={Wizard} />
                    <Route path="/map-jqvmap" exact component={JqvMap} />
                    <Route
                      path="/uc-lightgallery"
                      exact
                      component={Lightgallery}
                    />
                    <Route path="/edit-profile" exact component={EditProfile} />
                    <Route path="/ecom-invoice" exact component={Invoice} />
                    <Route
                      path="/ecom-product-detail"
                      exact
                      component={ProductDetail}
                    />
                   
                    <Route
                      path="/uc-sweetalert"
                      exact
                      component={MainSweetAlert}
                    />
                    <Route path="/uc-toastr" exact component={Toastr} />
                  </Switch>
                </div>
              </div>
              <Footer changeFooter="out-footer" />
            </Main>
          </div>
        </Route>

        {/* without footer  */}
        <Route path={["/activity", "/to-do-list", "/ui-modal", "/ui-popover"]}>
          <div id="main-wrapper" className="show">
            <Main>
              <Nav />
              <div className="content-body">
                <div className="container-fluid">
                  <Switch>
                   
                    <Route path="/ui-modal" exact component={UiModal} />
                    <Route path="/ui-popover" exact component={UiPopOver} />
                  </Switch>
                </div>
              </div>
            </Main>
          </div>
        </Route>
        {/* without footer and container */}
        <Route
          path={[
            "/file-manager",
            "/chat",
            "/email-compose",
            "/email-inbox",
            "/email-read",
          ]}
          exact
        >
          <div id="main-wrapper" className="show">
            <Main>
              <Nav />
              <div className="content-body message-body mh-auto">
                <div className="container-fluid mh-auto p-0">
                  <Switch>
                   
                    
                    <Route path="/email-compose" exact component={Compose} />
                    <Route path="/email-inbox" exact component={Inbox} />
                    <Route path="/email-read" exact component={Read} />
                  </Switch>
                </div>
              </div>
            </Main>
          </div>
        </Route>
        {/* change footer  */}
        <Route
          path={[
            "/ui-accordion",
            "/ui-alert",
            "/ui-badge",
            "/ui-button",
            "/ui-button-group",
            "/ui-list-group",
            "/ui-card",
            "/ui-carousel",
            "/ui-dropdown",
            "/ui-progressbar",
            "/ui-tab",
            "/ui-pagination",
          ]}
          exact
        >
          <div id="main-wrapper" className="show">
            <Main>
              <Nav />
              <div className="content-body">
                <div className="container-fluid">
                  <Switch>
                    <Route path="/ui-accordion" exact component={UiAccordion} />
                    <Route path="/ui-alert" exact component={UiAlert} />
                    <Route path="/ui-badge" exact component={UiBadge} />
                    <Route path="/ui-button" exact component={UiButton} />
                    <Route
                      path="/ui-button-group"
                      exact
                      component={UiButtonGroup}
                    />
                    <Route
                      path="/ui-list-group"
                      exact
                      component={UiListGroup}
                    />
                    <Route path="/ui-card" exact component={UiCards} />
                    <Route path="/ui-carousel" exact component={UiCarousel} />
                    <Route path="/ui-dropdown" exact component={UiDropDown} />
                    <Route
                      path="/ui-progressbar"
                      exact
                      component={UiProgressBar}
                    />
                    <Route path="/ui-tab" exact component={UiTab} />
                    <Route
                      path="/ui-pagination"
                      exact
                      component={UiPagination}
                    />
                  </Switch>
                </div>
              </div>
            </Main>
          </div>
        </Route>
        {/* default layout  */}
        <Route>
          <div id="main-wrapper" className="show">
            <Main>
              <Nav />
              <div
                className="content-body"
                style={{ minHeight: window.screen.height + 20 }}
              >
                <div className="container-fluid">
                  <Switch>
                    {routes.map((data, i) => (
                      <Route
                        key={i}
                        exact
                        path={`/${data.url}`}
                        component={data.component}
                      />
                    ))}
                  </Switch>
                  <Footer />
                </div>
              </div>
            </Main>
          </div>
        </Route>
      </Switch>
      <ScrollToTop />
    </>
  );
};

export default Markup;
