import { getAuthToken } from "../context/auth-context";
import { CALL_API } from "../context/constValues";
import Swal from "sweetalert2";
export async function getAllPaciente() {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/paciente`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();
  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function getPacientesById(idPaciente) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/paciente/${idPaciente}`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();
  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function getSearchPaciente(sqlSearch) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/paciente?sqlSearch=${sqlSearch}`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function getLocal() {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/local`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function getPacienteByProfesional(IdProfesional) {
  const token = getAuthToken();
  const response = await fetch(
    `${CALL_API}/paciente/profesional/${IdProfesional}`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function insertPaciente(pacienteObject) {
  const {
    nombre,
    apellido,
    fechadenacimiento,
    ci,
    edad,
    sexo,
    responsable,
    ciresponsable,
    ruc,
    razonsocial,
    correo,
    telefono,
    nresponsable,
    ciudad,
    direccion,
    peso,
    altura,
    diagnosticomedico,
    enfermedadesdebase,
    mpropia,
    equipoderehabilitacion,
    formadealimentacion,
    tiempodeasistencia,
    hs,
    direccionserv,
    alergiasamedicamentos,
    alergiasgral,
    comoconocio,
    obs1,
    obs2,
    viadealimentacion,
    definiralergia,
    definiralergiasgral,
    tarifa,
    nivel
  } = pacienteObject;

  console.log(pacienteObject);

  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/paciente`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      alergiasamedicamentos: alergiasamedicamentos,
      alergiasgral: alergiasgral,
      comoconocio: comoconocio,
      obs1: obs1,
      obs2: obs2,
      viadealimentacion: viadealimentacion,
      Nombre: `${nombre} ${apellido}`,
      FechaNacimiento: fechadenacimiento,
      Cedula: ci,
      Edad: edad,
      Sexo: sexo,
      FamiliarResponsable: responsable,
      CedulaResponsable: ciresponsable,
      Ruc: ruc,
      Razonsocia: razonsocial,
      Correo: correo,
      Telefono: telefono,
      Celular: nresponsable,
      Ciudad: ciudad,
      Direccion: direccion,
      Peso: peso,
      Altura: altura,
      DiagnosticoMedico: diagnosticomedico,
      EnfermedadBase: enfermedadesdebase,
      MovilidadPropia: mpropia,
      EquipoRehabilitacion: equipoderehabilitacion,
      FormaAlimentacion: formadealimentacion,
      TiempoAsistencia: tiempodeasistencia,
      Direccionserv: direccionserv,
      Guardiahs: hs,
      tarifa: tarifa,
      nivel: nivel
    }),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.isSaved;
}

export async function updatePaciente(pacienteObject) {
  console.log(pacienteObject);
  const {
    NombrePaciente,
    FechaNacimiento,
    Cedula,
    Edad,
    Sexo,
    FamiliarResponsable,
    CedulaResponsable,
    Ruc,
    RazonSocial,
    Correo,
    Telefono,
    Celular,
    Ciudad,
    Direccion,
    peso,
    altura,
    diagnosticoMedico,
    enfermedadBase,
    movilidadPropia,
    equipoRehabilitacion,
    formaAlimentacion,
    tiempoAsistencia,
    Guardiahs,
    Direccionserv,
    IdPacientes,
    comoconocio,
    obs1,
    obs2,
    viadealimentacion,
    alergiasamedicamentos,
    alergiasgral,
    definiralergia,
    definiralergiasgral,
    tarifa,
    nivel
  } = pacienteObject;

  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/paciente/updatepaciente`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      Nombre: NombrePaciente,
      FechaNacimiento: FechaNacimiento,
      Cedula: Cedula,
      Edad: Edad,
      Sexo: Sexo,
      FamiliarResponsable: FamiliarResponsable,
      CedulaResponsable: CedulaResponsable,
      Ruc: Ruc,
      Razonsocial: RazonSocial,
      Correo: Correo,
      Telefono: Telefono,
      Celular: Celular,
      Ciudad: Ciudad,
      Direccion: Direccion,
      Peso: peso,
      Altura: altura,
      DiagnosticoMedico: diagnosticoMedico,
      EnfermedadBase: enfermedadBase,
      MovilidadPropia: movilidadPropia,
      EquipoRehabilitacion: equipoRehabilitacion,
      FormaAlimentacion: formaAlimentacion,
      TiempoAsistencia: tiempoAsistencia,
      Direccionserv: Direccionserv,
      Guardiahs: Guardiahs,
      idPacientes: IdPacientes,
      comoconocio: comoconocio,
      obs1: obs1,
      obs2: obs2,
      viadealimentacion: viadealimentacion,
      alergiasamedicamentos: alergiasamedicamentos,
      alergiasgral: alergiasgral,
      definiralergia: definiralergia,
      definiralergiasgral: definiralergiasgral,
      tarifa: tarifa,
      nivel: nivel
    }),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(`${data.message}`);
  }

  return data.isSaved;
}
export async function updatecuentacliente(pacienteObject) {
  console.log(pacienteObject);
  const {
    data1, data2, data3
  } = pacienteObject;
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/updatecuentacliente`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: data1,
      estadocuenta: data2,
      pass: data3
    }),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(`${data.message}`);
  }

  return data.isSaved;
}
export async function deletePaciente(idPaciente) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/paciente/${idPaciente}`, {
    method: "DELETE",
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.isDeleted;
}

export async function EstadoPaciente(usuarioObject) {
  const { id, motivo } = usuarioObject;
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/updateEstadoPaciente`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      idpaciente: id,
      motivo: motivo,
    }),
  });
  const data = await response.json();
  if (!response.ok) {
    Swal.fire({
      title: "Error!",
      text: `${data.messege}`,
      icon: "error",
      confirmButtonText: "Ok",
    });
    throw new Error("Can not sign up this user");
  }
  return data;
}
