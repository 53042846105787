import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
const ModalCuentaApp = (props) => {
  const {
    estado,
    onCloseModal,
    onReturnValue,
    datos
  } = props;
  const [data,setData]=useState(datos)
  const handleSubmit2 = (event) => {
    event.preventDefault();
    onReturnValue(data.IdPacientes,data.estadousuarioapp,data.pass)
  };
  return (
    <>
      <Modal show={estado} onHide={onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Cuenta App</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit2} id="auFORM">
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Estado Cuenta 
              </label>
              <select
              className="form-control"
              defaultValue={data.estadousuarioapp}
              onChange={(e) => {
                setData({ ...data, estadousuarioapp: e.target.value });
              }}
            >
              <option value="ACTIVO">ACTIVO</option>
              <option value="INACTIVO">INACTIVO</option>
            </select>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Contraseña
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="password"
                  className="form-control"
                  onChange={(e) => {
                    setData({ ...data,pass: e.target.value });
                  }}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onCloseModal}>
            Cerrar
          </Button>
          <Button type="submit" form="auFORM">
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalCuentaApp;