import { getAuthToken } from "../context/auth-context";
import { CALL_API } from "../context/constValues";

export async function getAllAsignaciones() {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/asignacion`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function getAsignacionByPaciente(idPaciente) {
  const token = getAuthToken();
  const response = await fetch(
    `${CALL_API}/asignacion-paciente?idPaciente=${idPaciente}`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function getAsignacionesByUserAndClient(idPaciente, idUsuario) {
  const token = getAuthToken();
  const response = await fetch(
    `${CALL_API}/buscar/asignacion?idPaciente=${idPaciente}&idUsuario=${idUsuario}`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function getUserasignaciones() {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/asignacion/Verasignaciones`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function insertAsignacion(profesionalObject) {
  const {
    idPaciente,
    NombrePaciente,
    usuario,
    usuarioAsignado,
    modalidad,
    honorariohora,
    guardiahs,
    tarifahora,
  } = profesionalObject;

  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/asignacion`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      idPaciente: idPaciente,
      NombrePaciente: NombrePaciente,
      idUsuarioAsignado: usuario,
      usuarioAsignado: usuarioAsignado,
      modalidad: modalidad,
      honorarioenfermero: honorariohora,
      tarifahora: tarifahora,
      guardiahs: guardiahs,
    }),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.isSaved;
}

export async function updateAsignacion(profesionalObject) {
 
  const {
    IdPaciente,
    IdUsuarioAsignaado,
    UsuarioAsignado,
    Modalidad,
    honorarioenfermero,
    IdProfesionalPaciente,
    guardiahs,
    tarifahora
  } = profesionalObject;
  const token = getAuthToken();
  const response = await fetch(
    `${CALL_API}/asignacion/${IdProfesionalPaciente}`,
    {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idPaciente: IdPaciente,
        idUsuario: IdUsuarioAsignaado,
        usuarioAsignado: UsuarioAsignado,
        modalidad: Modalidad,
        honorarioenfermero: honorarioenfermero,
        guardiahs:guardiahs,
        tarifahora:tarifahora
      }),
    }
  );
  const data = await response.json();
  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.isSaved;
}

export async function delteAsignacion(idProfesionalPaciente) {
  const token = getAuthToken();
  const response = await fetch(
    `${CALL_API}/asignacion-edit/${idProfesionalPaciente}`,
    {
      method: "PUT",
      headers: { Authorization: "Bearer " + token },
    }
  );

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.isSaved;
}
