import React, { useEffect, useState, useReducer } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useCallback } from "react";
import Swal from "sweetalert2";
import { ButtonGroup, Button } from "react-bootstrap";
import ModalLiquidar from "./ModalLiquidar";
import ModalEditarLegajo from "./ModalEditarLegajo"
import ModalLegajo from "./ModalLegajo"
import genPDF from "./pdfWriter";
import genPDF2 from "./pdfWriter2";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import pdfrecibo from "./pdfrecibo"
import {
  getliquidacionbyid,
  getlegajosbyliquidacion,
  liquidarliquidacion,
  InsertRecibo,
  getidrecibo,
  AddLegajo,
  EditLegajo
} from "../../../api/marcacionApi";
import { Table } from "react-bootstrap";
import { getPacientesById } from "../../../api/pacienteApi";
import { getUsuarioById } from "../../../api/usuarioApi";
const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
      };
    case "SHOW2":
      return {
        isShow2: true,
      };
    case "SHOW3":
      return {
        isShow3: true,
      };
    case "END":
      return {
        ...curShow,
        isShow: false,
        isShow2: false,
        isShow3: false,
      };
  }
};

const FichaLiquidacion = () => {
  const location = useLocation();
  const { id, nombrepaciente } = location.state;
  const [liquidaciondata, getliquidaciondata] = useState([]);
  const [isliqui, setisliqui] = useState();
  const [lejosliquidados, getlegajosliquidados] = useState([]);
  const [sumatarifa, setsumtarifa] = useState("");
  const [sumahonorarios, setsumhonorarios] = useState("");
  const [sumahoras, setsumhoras] = useState("");
  const [sumahorashonorario, setsumhorashonorario] = useState("");
  const [listaprofesionales, setlista] = useState([]);
  const [rowdata, setrowdata] = useState();
  const [legajosprof, setlegajosprof] = useState([]);
  const [liquidacionprincipal, setliquidacionprincipal] = useState([]);
  const [liquidacionhonorario, setliquidacionhonorario] = useState([]);
  const [Paciente, setPaciente] = useState()
  const history = useHistory();
  const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,
    isShow2: false,
  });

  const handleShow = () => {
    dispatchReducer({ type: "SHOW" });
  };
  const handleShow2 = (row) => {
    console.log(row)
    setrowdata(row)
    dispatchReducer({ type: "SHOW2" });
  };
  const handleShow3 = () => {
    dispatchReducer({ type: "SHOW3" });
  };
  function horasalida(fecha, hora, horas) {
    let fechatotal = new Date(`${fecha} ${hora}`);
    const hoursToMs = fechatotal.getTime() + horas * 60 * 60 * 1000;

    fechatotal.setTime(hoursToMs);
    const showTime =
      addZeroBefore(fechatotal.getHours()) +
      ":" +
      addZeroBefore(fechatotal.getMinutes()) +
      ":" +
      addZeroBefore(fechatotal.getSeconds());

    return `${showTime}`;
  }
  function addZeroBefore(n) {
    return (n < 10 ? "0" : "") + n;
  }
  const proftabs = (idprofesional) => {
    const _ = require("lodash");
    const sumhorasprof = _.sumBy(legajosprof, (item2) =>
      Number((item2.horascalculadas + item2.horashoraextra).toFixed(2))
    );
    const sumhonorario = _.sumBy(legajosprof, (item2) =>
      Number(
        Math.round(item2.CostoHoraProfesional * (item2.horashoraextra + item2.horascalculadas))
      )
    );

    return (
      <Tab eventKey={idprofesional.id} title={idprofesional.name}>
        <Table
          id="my-table"
          responsive
        >
          <thead className="thead-primary">
            <tr>
              <th scope="col">Fecha Entrada</th>
              <th scope="col">Hora Entrada</th>
              <th scope="col">Fecha Salida</th>
              <th scope="col">Hora Salida</th>
              <th scope="col">Horas</th>
              <th scope="col">Honorario</th>
              <th scope="col">Enfermero</th>
            </tr>
          </thead>
          <tbody>
            {legajosprof.map((item2, index2) => {

              let horas = item2.horascalculadas + item2.horashoraextra;
              let honorario =
                item2.CostoHoraProfesional * horas;
              return (
                <>
                  <tr key={index2}>
                    <td>{item2.Fecha}</td>
                    <td>{item2.HoraEntrada}</td>
                    <td>{item2.FechaSalida}</td>
                    <td>
                      {horasalida(
                        item2.Fecha,
                        item2.HoraEntrada,
                        item2.horascalculadas + item2.horashoraextra
                      )}
                    </td>
                    <td>{horas}</td>
                    <td>{Math.round(honorario).toLocaleString('es')}</td>
                    <td>{item2.Usuario}</td>
                  </tr>
                </>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={4}>Total</td>
              <td>{sumhorasprof.toLocaleString('es')}</td>
              <td>{sumhonorario.toLocaleString('es')}</td>
            </tr>
          </tfoot>
        </Table>
        <Button className="ml-2" onClick={descargarpdfprof}>Descargar PDF</Button>
        <Button onClick={descargarrecivo}>Descargar Recibo</Button>
      </Tab>
    );
  };
  const onCloseModal = () => {
    dispatchReducer({ type: "END" });
  };
  const Liquidar = async (data) => {
    dispatchReducer({ type: "END" });
    try {
      let idliquidacion = id;
      const result = await liquidarliquidacion({ ...data, idliquidacion });
      if (result) {
        Swal.fire({
          title: "Datos de la liquidacion",
          html: `Actualizados`,
          icon: "success",
        });
        assigmentValue();
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
    //liquidar
  };
  const NewLegajo = async (data) => {
    dispatchReducer({ type: "END" });
    try {
      let idliquidacion = id;
      let idPaciente = lejosliquidados[0].IdPaciente
      let paciente = nombrepaciente
      await AddLegajo({ ...data, idliquidacion,idPaciente,paciente});
      assigmentValue();
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
    //liquidar
  };
  const EditLegajoFunc = async (data) => {
    dispatchReducer({ type: "END" });
    try {
    await EditLegajo(data);
     assigmentValue();
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
    //liquidar
  };
  const assigmentValue = useCallback(async () => {
    console.log(nombrepaciente)
    try {
      Swal.fire({
        title: "Cargando Datos",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
      const data = await getliquidacionbyid(id); // datos del cierre
      data[0].estado == "Liquidado" ? setisliqui(true) : setisliqui(false);
      getliquidaciondata(data);


      // legajos
      const data1 = await getlegajosbyliquidacion(id);
      const liquidacionTarifa = data1.filter((num) => (num.paraTarifa === "SI"));
      const liquidacionHonorario = data1.filter((num) => (num.paraHonorario === "SI"));
      const result = data1.map((number) => {
        const container = {};

        container.id = number.IdUsuario;
        container.name = number.Usuario;

        return container;
      });

      const listprof = new Map(result.map((c) => [c.id, c])); // los profesionales
      const uniquepprof = [...listprof.values()];
      const _ = require("lodash");
      // sumas liquidacion tarifa y liquidacion honorario
      const sumhoras = _.sumBy(liquidacionTarifa, (item2) =>
        Number(item2.horascalculadas + item2.horashoraextra)
      );
      const sumtarifa = _.sumBy(liquidacionTarifa, (item2) =>
        Number(
          item2.CostoHoraEmpresa * (item2.horashoraextra + item2.horascalculadas)
        )
      );
      const sumhonorario = _.sumBy(liquidacionHonorario, (item2) =>
        Number(
          item2.CostoHoraProfesional * (item2.horashoraextra + item2.horascalculadas)
        )
      );
      const sumhorashonorario = _.sumBy(liquidacionHonorario, (item2) =>
        Number(item2.horascalculadas + item2.horashoraextra)
      );


      setsumhoras((sumhoras.toFixed(2)));
      setsumtarifa(Math.round(sumtarifa));
      setsumhonorarios(Math.round(sumhonorario));
      setsumhorashonorario((sumhorashonorario.toFixed(2)));
      getlegajosliquidados(data1);
      setlista(uniquepprof);
      setliquidacionprincipal(liquidacionTarifa);
      setliquidacionhonorario(liquidacionHonorario); //liuidacion principal trae todo lo que tenga id liquidacion luego se filtra
      // cargar Liquidacion
      // agregar boton de Liquidar
      // actualizar
      // traer legajos correspondientes
      Swal.close();
    } catch (err) {
      Swal.close();
      console.log(err);
    }
  }, []);
  useEffect(() => {
    assigmentValue();
  }, [assigmentValue]);

  const Tabclick = (k) => {
    setKey(k);
    let filterbyprof = liquidacionhonorario.filter((num) => (num.paraHonorario === "SI" && num.IdUsuario==k));
    setlegajosprof(filterbyprof);
  };
  const [key, setKey] = useState("Principal");
  // descargas pdfs
  const descargarpdf = () => {
    let dato = liquidacionprincipal
    let tipo = "Tarifa"
    genPDF({ dato, tipo })
    assigmentValue()
  }
  const descargarpdfhonorarios = () => {
    let dato = liquidacionprincipal
    let tipo = "Honorario"
    genPDF({ dato, tipo })
    assigmentValue()
  }
  const descargarpdfprof = async () => {
    const Usuario = await getUsuarioById(legajosprof[0].IdUsuario)
    let NombreUsuario = Usuario[0].Nombre
    genPDF2({ legajosprof, NombreUsuario })
    assigmentValue()
  }
  const descargarrecivo = async () => {
    Swal.fire({
      title: "Generando",
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const datos = legajosprof
    let iduser = datos[0].IdUsuario
    let len = datos.length
    let fechafin = datos[len - 1].FechaSalida
    let fechain = datos[0].Fecha
    const Paciente = await getPacientesById(datos[0].IdPaciente)
    const Usuario = await getUsuarioById(datos[0].IdUsuario)
    let idrecibo = await getidrecibo(datos[0].IdUsuario, id)
    const _ = require("lodash");
    const Honorario = _.sumBy(datos, (item2) =>
      Number(item2.CostoHoraProfesional + (item2.horashoraextra * item2.horascalculadas))
    );
    const nombrepaciente = Paciente[0].NombrePaciente
    const nombreusuario = Usuario[0].Nombre
    if (idrecibo.length > 0) {
      let idrec = idrecibo[0].idrecibo
      pdfrecibo({ nombrepaciente, nombreusuario, Honorario, idrec, fechain, fechafin })
    }
    else {
      const idrecibo2 = await InsertRecibo({ nombrepaciente, nombreusuario, Honorario, iduser, id })
      idrecibo = await getidrecibo(datos[0].IdUsuario, id)
      let idrec = idrecibo[0].idrecibo
      pdfrecibo({ nombrepaciente, nombreusuario, Honorario, idrec, fechain, fechafin })
    }
    Swal.close();
    assigmentValue()
  }

  return (
    <>
      {liquidaciondata.map((item, index) => {
        return (
          <div key={index}>
            <div className="col-xl-12">
              <div className="page-titles acti-space">
                <h2 className="heading sm-mb-0 mb-2">{Paciente}</h2>
                <div className="d-flex align-items-center flex-wrap">
                  <div className="me-2">
                    <ButtonGroup aria-label="Basic example">
                      <Button
                        variant="primary"
                        disabled={isliqui}
                        onClick={handleShow}
                      >
                        Liquidar
                      </Button>

                    </ButtonGroup>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-xxl-12">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Datos de Cierre</h4>
                    </div>
                    <div className="card-body p-4">
                      <span>Generado el: {item.FechaCreacion} </span>
                      <br></br>
                      <span> Generado por : {item.UsuarioCreacion} </span>
                      <br></br>
                      <span>Liquidado por : {item.UsuarioEdicion} </span>
                      <br></br>
                      <span>Factura numero : {item.facturanumero} </span>
                      <br></br>
                      <span>Estado : {item.estado}</span>
                      <br></br>
                      <span>Fecha inicio: {item.fechainicio}</span>
                      <br></br>
                      <span>Fecha fin : {item.fechafin} </span>
                      <br></br>
                      <span>Obs. : <strong>{item.obs}</strong> </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-xl-12">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Liquidacion</h4>
                    </div>
                    <div className="card-body p-4">
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => Tabclick(k)}
                        className="mb-3"
                      >
                        <Tab eventKey="Principal" title="Principal">
                          <Table
                            responsive
                            className="primary-table-bordered col-6 "
                            id="my-table"

                          >
                            <thead className="thead-primary">
                              <tr>
                                <th scope="col">Fecha Entrada</th>
                                <th scope="col">Hora Entrada</th>
                                <th scope="col">Hora Salida</th>
                                <th scope="col">Horas</th>
                                <th scope="col">Tarifa</th>
                                <th scope="col">Enfermero</th>
                                <th scope="col">Opciones</th>
                              </tr>
                            </thead>
                            <tbody>
                              {liquidacionprincipal.map((item2, index3) => (
                                <tr key={index3}>
                                  <td>{item2.Fecha}</td>
                                  <td>{item2.HoraEntrada}</td>
                                  <td>
                                    {horasalida(
                                      item2.Fecha,
                                      item2.HoraEntrada,
                                      item2.horascalculadas +
                                      item2.horashoraextra
                                    )}
                                  </td>
                                  <td>
                                    {item2.horascalculadas +
                                      item2.horashoraextra}
                                  </td>
                                  <td>
                                    {Math.round(item2.CostoHoraEmpresa *
                                      (item2.horashoraextra + item2.horascalculadas)).toLocaleString('es')}
                                  </td>
                                  <td>{item2.Usuario}</td>
                                  <td><Button  onClick={()=>handleShow2(item2)} className="p-1">
                                    <i
                                      className="material-icons"
                                      style={{ fontSize: "16px" }}
                                    >
                                      {" "}
                                      mode_edit{" "}
                                    </i>
                                  </Button></td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan={3}>Total</td>
                                <td>{sumahoras.toLocaleString('es')}</td>
                                <td>{sumatarifa.toLocaleString('es')}</td>
                              </tr>
                            </tfoot>
                          </Table>
                          <Button onClick={descargarpdf}>Descargar PDF</Button>
                          <Button className="m-2" onClick={handleShow3}><i
                            className="material-icons"
                            style={{ fontSize: "16px" }}
                          >
                            {" "}
                            add{" "}
                          </i></Button>

                        </Tab>
                        {/*Prof totales*/}
                        <Tab eventKey="Principal2" title="Honorarios">
                          <Table
                            responsive
                            className="primary-table-bordered col-6 "
                            id="my-table"

                          >
                            <thead className="thead-primary">
                              <tr>
                                <th scope="col">Fecha Entrada</th>
                                <th scope="col">Hora Entrada</th>
                                <th scope="col">Hora Salida</th>
                                <th scope="col">Horas</th>
                                <th scope="col">Honorario</th>
                                <th scope="col">Enfermero</th>
                                <th scope="col">Opciones</th>
                              </tr>
                            </thead>
                            <tbody>
                              {liquidacionhonorario.map((item2, index3) => (
                                <tr key={index3}>
                                  <td>{item2.Fecha}</td>
                                  <td>{item2.HoraEntrada}</td>
                                  <td>
                                    {horasalida(
                                      item2.Fecha,
                                      item2.HoraEntrada,
                                      item2.horascalculadas +
                                      item2.horashoraextra
                                    )}
                                  </td>
                                  <td>
                                    {item2.horascalculadas +
                                      item2.horashoraextra}
                                  </td>
                                  <td>
                                    {Math.round(item2.CostoHoraProfesional *
                                      (item2.horashoraextra + item2.horascalculadas)).toLocaleString('es')}
                                  </td>
                                  <td>{item2.Usuario}</td>
                                  <td><Button  onClick={()=>handleShow2(item2)} className="p-1">
                                    <i
                                      className="material-icons"
                                      style={{ fontSize: "16px" }}
                                    >
                                      {" "}
                                      mode_edit{" "}
                                    </i>
                                  </Button></td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan={3}>Total</td>
                                <td>{sumahorashonorario.toLocaleString('es')}</td>
                                <td>{sumahonorarios.toLocaleString('es')}</td>
                              </tr>
                            </tfoot>
                          </Table>
                          <Button onClick={descargarpdfhonorarios}>Descargar PDF</Button>

                        </Tab>
                        {listaprofesionales.map((item2) => proftabs(item2))}
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {reducer.isShow && (
        <ModalLiquidar
          estado={reducer.isShow}
          onCloseModal={onCloseModal}
          onReturnValue={Liquidar}
        />
      )}
      {reducer.isShow2 && (
        <ModalEditarLegajo
          estado={reducer.isShow2}
          onCloseModal={onCloseModal}
          onReturnValue={EditLegajoFunc}
          isNew={false}
          rowdata={rowdata}
        />
      )}
      {reducer.isShow3 && (
        <ModalLegajo
          estado={reducer.isShow3}
          onCloseModal={onCloseModal}
          onReturnValue={NewLegajo}
          isNew={true}
        />
      )}
    </>
  );
};
export default FichaLiquidacion;
